export const SettingsRouting: any = [];

SettingsRouting['template1'] = {
  path: '',
  loadChildren: () => import('./template1/layouts/layouts.module').then(m => m.LayoutsModule)
}

SettingsRouting['template2'] = {
  path: '',
  loadChildren: () => import('./template2/layouts/layouts.module').then(m => m.LayoutsModule2)
}

SettingsRouting['template3'] = {
  path: '',
  loadChildren: () => import('./template3/layouts/layouts.module').then(m => m.LayoutsModule3)
}

SettingsRouting['template4'] = {
  path: '',
  loadChildren: () => import('./template4/layouts/layouts.module').then(m => m.LayoutsModule4)
}
