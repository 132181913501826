import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EntityData } from 'src/app/models/entity-app.model';
import { PropertyModel } from 'src/app/models/property.model';
import { EventService } from 'src/app/services/event.service';
import { Platform } from '@angular/cdk/platform';
// @ts-ignore
import * as html2pdf from 'html2pdf.js';
declare var $: any;

@Component({
  selector: 'app-property-print',
  templateUrl: './property-print.component.html',
  styleUrls: ['./property-print.component.css']
})
export class PropertyPrintComponent implements OnInit {

  @Input() property!: PropertyModel;
  @Input() entityData!: EntityData;
  @Input() qrCodeLink: any;
  @Input() template: any;
  energyFile: any;

  constructor(
    private eventService: EventService,
    private platform: Platform) { }

  ngOnInit(): void {
    this.eventService.subscribe('open-print', data => {
      this.property = data.property;
      const energy = ['A', 'A+', 'B', 'B-', 'C', 'D', 'E', 'F', 'G'];
    this.energyFile = this.property.energyCertification?.certificate && energy.includes(this.property.energyCertification?.certificate)
      ? this.property.energyCertification.certificate
      : 'exempted';
      if (this.property.description) {
        this.property.description = this.property.description.replace(/<p>&nbsp;<\/p>/g, '');
      }
      this.qrCodeLink = data.link;
      setTimeout(() => {
        // this.generatePDF();
        if (this.platform.SAFARI) {
          this.generatePDF();
        } else {
          window.print();
        }
      }, 600)
    });
  }

  priceFormat(propertyPrice: any, symbol: any = '€') {
    if (propertyPrice) {
      let price = propertyPrice.toString().split('.');
      const spaces = (price: any) => String(price)
        .replace(
          /(?!^)(?=(?:\d{3})+$)/g,
          ' '
        );
      const price1 = spaces(price[0]);
      if (price.length > 1)
        return symbol + ' ' + price1 + ',' + price[1];

      return symbol + ' ' + price1;
    }
    return propertyPrice;
  }

  generatePDF() {
    const element = document.getElementById(this.template === 'template2' ? 'template2' : 'default-print');

    // Criar um clone do elemento para evitar alterar o original
    const clonedElement = element?.cloneNode(true) as HTMLElement;
    $(clonedElement).css("display", "block");

    const qrCanvas = element?.querySelector('canvas');
    if (qrCanvas) {
      const img = document.createElement('img');
      img.src = (qrCanvas as HTMLCanvasElement).toDataURL('image/png'); // Converte o canvas para imagem
      img.style.width = qrCanvas.style.width; // Mantém o tamanho original
      img.style.height = qrCanvas.style.height;
      const qr = clonedElement.querySelector('canvas');
      if (qr)
        qr.replaceWith(img); // Substitui o canvas pelo img
    }

    // Gerar o PDF
    html2pdf()
      .from(clonedElement)
      .set({
        filename: `${this.property?.reference}.pdf`,
        image: { type: 'jpeg' },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4' }
      })
      .save();
  }

}
